<template>
  <div
    :class="{
      'growing-text-field': true,
      [type]: true,
      'show-message': hasError || !hideDetails,
    }"
  >
    <v-text-field
      v-model="model"
      dense
      outlined
      ref="input"
      :hide-details="hideDetails"
      :error="hasError"
      :type="type"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      @update:error="checkForError"
      @blur="checkForError"
      @focus="checkForError"
    />
    <div class="spacer">{{ model ? model : placeholder }}</div>
    <div v-if="error" class="spacer error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
    },

    placeholder: {
      type: String,
      required: false,
    },

    type: {
      type: String,
      required: false,
      default: "text",
    },

    rules: {
      type: Array,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    //updates the bound model immediately
    immediateUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideDetails: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
  },

  data() {
    return {
      model: this.value,
      timeout: 0,
      error: null,
      hasError: false,
    };
  },

  mounted() {
    this.$nextTick(() => this.checkForError());
  },

  watch: {
    value(value) {
      if (this.isNumber) {
        value = Number(value);
      }

      this.model = value;
    },

    model(model) {
      if (this.isNumber) {
        model = Number(model);
      }

      this.checkForError();
      if (this.immediateUpdate) {
        this.$emit("input", model);
        return;
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("input", model);
      }, 300);
    },
  },

  methods: {
    checkForError() {
      const input = this.$refs.input;
      input.validate();
      this.$nextTick(() => {
        const hasError = !!input?.errorBucket?.length > 0;
        this.hasError = hasError;
        if (hasError) {
          this.error = input.errorBucket?.[0];
          return;
        }
        this.error = null;
      });
    },
  },

  computed: {
    isNumber() {
      return this.type === "number" || this.type === "integer";
    },
  },
};
</script>

<style scoped>
.growing-text-field {
  display: inline-block;
  position: relative;
  min-height: 40px;
  overflow: visible;
  min-width: 100px;
  max-width: 600px;
}

.growing-text-field.number {
  min-width: 50px;
}

.growing-text-field.show-message {
  min-height: 66px;
}

.growing-text-field > .v-text-field {
  position: absolute;
  width: 100%;
}

.growing-text-field > .v-text-field::v-deep .v-messages__message {
  white-space: nowrap;
}

.growing-text-field > .spacer {
  position: relative;
  font: inherit;
  visibility: hidden;
  white-space: nowrap;
  top: 0;
  margin: 0 12px;
}

.growing-text-field.number > .spacer {
  margin: 0 24px;
}

.growing-text-field.number > .error-spacer {
  line-height: 12px;
  font-size: 12px;
}
</style>